var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center",class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('div',{staticClass:"common-perks-gallery",style:(_vm.cssProps)},[_c('h2',{staticClass:"common-perks-gallery__title"},[_vm._v("\n          "+_vm._s(_vm.block?.title)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"common-perks-gallery__grid",style:({ 'row-gap': `${_vm.block?.verticalGap}px`, 'column-gap': `${_vm.block?.horizontalGap}px` })},_vm._l((_vm.block?.icons),function(icon,index){return _c('div',{key:index,staticClass:"common-perks-gallery__grid-icon"},[(icon?.icon)?_c('nuxt-img',{staticClass:"common-perks-gallery__grid-icon-image",attrs:{"src":icon?.icon,"loading":"lazy","alt":icon?.title}}):_vm._e(),_vm._v(" "),_c('h4',{staticClass:"common-perks-gallery__grid-icon-title"},[_vm._v(_vm._s(icon?.title))])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }