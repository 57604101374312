
import { defineComponent, PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'
export default defineComponent({
  name: 'CommonPerksGallery',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },
  computed: {
    cssProps() {
      return {
        //@ts-ignore
        '--column-gap-sm': (this.block?.horizontalGap / 2) + "px",
      }
    }
  }
})
